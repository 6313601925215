<template>
  <ul class="e-teaser-list">
    <teaser-list-item v-for="event in events" :key="event.id" :an-event="event">
      <slot v-bind:event="event"></slot>
    </teaser-list-item>
  </ul>
</template>

<script>
import TeaserListItem from './TeaserListItem.vue'
export default {
  name: 'TeaserList',
  props: {
    events: {
      type: Array
    }
  },
  components: { TeaserListItem }
}
</script>
<style lang="scss">
@import '_assets/TeaserList';
</style>
