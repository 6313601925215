<template>
  <router-link :class="['e-status-link', `e-status-link--${status}`]" :to="to">
    <span :class="[icon]"></span>
  </router-link>
</template>

<script>
export default {
  name: 'StatusLink',
  props: {
    status: {
      type: String
    },
    to: {
      type: Object
    }
  },
  computed: {
    icon() {
      switch (this.status) {
        case 'booked':
          return 'el-icon-check'
        default:
          return 'el-icon-arrow-right'
      }
    }
  }
}
</script>
<style lang="scss">
@import '_assets/StatusLink';
</style>
