<template>
  <teaser-box
    class="counseling-events-teaser"
    :header-title="$t('system.navigation.counselingEvents')"
  >
    <div class="teaser-box-icon">
      <span class="teaser-box-icon__icon e-mobile-hidden" role="presentation">
        <icon-calendar class="counseling-events-teaser__icon" :class="{ 'counseling-events-teaser__icon--active': numberOfAvailableEvents > 0 }"/>
      </span>
      <p class="teaser-box-icon__text">
        <template v-if="loggedIn">
          <router-link
            class="teaser-link"
            :to="{ name: 'counselingEventsOverview' }"
            >{{ $t('counselingEvent.availableEvents') }}</router-link
          >
          ({{ numberOfAvailableEvents }}) /
          <router-link
            class="teaser-link"
            :to="{ name: 'counselingEventsOverview' }"
            >{{ $t('counselingEvent.bookedEvents') }}</router-link
          >
          ({{ bookedEvents.length }})
        </template>
        <template v-else>
          <template v-if="!(numberOfAvailableEvents > 0)">
            {{ $t('counselingEvent.noItems') }}
          </template>
          <template v-else>
            {{ $t('counselingEvent.descriptionText') }}
          </template>
        </template>
      </p>
    </div>
    <teaser-list
      class="mt-2 mb-2"
      :events="counselingEvents.slice(0, 3).map(toListItem)"
    >
      <template v-if="loggedIn" v-slot:default="{ event }">
        <status-link
          class="teaser-list-item__status"
          :to="{ name: 'counselingEventsOverview', params: { id: event.id } }"
          :status="event.status"
        />
      </template>
    </teaser-list>
    <router-link
      v-if="loggedIn"
      class="e-btn e-btn--tertiary mt-auto e-mobile-hidden"
      :class="{ 'is-disabled': counselingEvents.length === 0 }"
      :to="{ name: 'counselingEventsOverview' }"
    >
      <span class="flex align-center">
        <span v-text="$t('counselingEvent.more')" />
      </span>
    </router-link>
  </teaser-box>
</template>

<script>
import TeaserBox from 'molecules/TeaserBox/TeaserBox'
import TeaserList from '../../molecules/TeaserList/TeaserList.vue'
import IconCalendar from 'assets/icons/calendar.svg'
import StatusLink from './StatusLink.vue'
import { toListItem } from '../../../shared/util/counselingEvent'

export default {
  name: 'CounselingEventsTeaserBox',
  components: { TeaserBox, TeaserList, StatusLink, IconCalendar },
  props: {
    counselingEvents: {
      type: Array
    },
    loggedIn: {
      type: Boolean
    },
    totalCount: {
      type: Number
    }
  },
  computed: {
    numberOfAvailableEvents() {
      const total = this.totalCount ?? this.counselingEvents.length
      return total - this.bookedEvents.length
    },
    bookedEvents() {
      return this.counselingEvents.filter(event => event.status === 'booked')
    }
  },
  methods: {
    toListItem(counselingEvent) {
      return toListItem(counselingEvent, this)
    }
  }
}
</script>
<style lang="scss" scoped>
.counseling-events-teaser {
  .teaser-box-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #808080;
    &__icon {
      $size: 5rem;
      $color: #c7c7c7;
      height: $size;
      font-size: $size;
      color: $color;
      fill: $color;
      margin-bottom: 1rem;
    }

    &__text {
      font-size: 0.75rem;
    }
  }

  .teaser-link {
    color: inherit;
  }

  .teaser-list {
    &:last-child {
      margin-bottom: 0;
    }
  }
  .teaser-list-item__status {
    width: 40px;
    margin-left: auto;
    flex-shrink: 0;
  }
  & &__icon {
    display: flex;
    height: 100%;

    &--active {
      .user {
      fill: c('primary');
    }
    }
  }
}
</style>
