<template>
  <article class="e-teaser-box">
    <header class="e-teaser-box__header">
      <h3 v-text="headerTitle" class="e-teaser-box__header-heading" />
    </header>
    <div class="e-teaser-box__body">
      <slot></slot>
    </div>
  </article>
</template>

<script>
export default {
  name: 'TeaserBox',
  props: {
    headerTitle: {
      type: String
    },
    actionButtonText: {
      type: String
    }
  }
}
</script>
<style lang="scss">
@import '_assets/TeaserBox';
</style>
